var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "rounded-lg",
      attrs: { dark: "", color: "rgba(0,0,0,0.4)" },
    },
    [
      _c(
        "v-subheader",
        { staticClass: "title" },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "subtitle-2" },
                [
                  _vm._v("Perfiles\n        "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        color: "success",
                        loading: _vm.loading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.navigateToName("profile-presence")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-account-supervisor-outline")])],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _c("v-select", {
                    attrs: {
                      label: "Agencias",
                      "item-text": "name",
                      "item-value": "id",
                      items: _vm.agencies,
                      loading: _vm.loading,
                      "hide-details": "",
                      filled: "",
                      outlined: "",
                      dense: "",
                      clearable: "",
                    },
                    model: {
                      value: _vm.agency,
                      callback: function ($$v) {
                        _vm.agency = $$v
                      },
                      expression: "agency",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        color: "success",
                        loading: _vm.loading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.list()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-reload")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", color: "red", loading: _vm.loading },
                      on: {
                        click: function ($event) {
                          return _vm.getProgress()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-reload")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        { staticClass: "pt-0 accounts-container custom-scroll" },
        [
          _c(
            "v-list",
            {
              staticClass: "rounded-lg",
              attrs: {
                subheader: "",
                "two-line": "",
                dense: "",
                color: "rgba(0,0,0,0.4)",
              },
            },
            _vm._l(_vm.showProfiles, function (profile) {
              return _c(
                "v-list-item",
                { key: profile.id },
                [
                  _c(
                    "v-badge",
                    {
                      attrs: {
                        "offset-x": "25",
                        "offset-y": "25",
                        overlap: "",
                        color: profile.active_profile_arrangement
                          ? ""
                          : "amber",
                        icon: profile.active_profile_arrangement
                          ? ""
                          : "mdi-alert-circle",
                      },
                    },
                    [
                      _c(
                        "v-list-item-avatar",
                        { attrs: { color: "blue", size: "60" } },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "", color: "blue" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-img",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  src: `/storage/photo/profile/${
                                                    profile.photo || "nophoto"
                                                  }.jpg`,
                                                },
                                              },
                                              "v-img",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(profile.amolatina_id)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "", color: "blue" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "span",
                                                  _vm._g(
                                                    _vm._b(
                                                      {},
                                                      "span",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        profile.profile_progress
                                                          ? _vm.formatNumber(
                                                              profile
                                                                .profile_progress
                                                                .points_day || 0
                                                            )
                                                          : 0
                                                      ) +
                                                        "  /  " +
                                                        _vm._s(
                                                          profile.user_profile_sum_goal_day ||
                                                            100
                                                        )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [_vm._v("Meta Dia")]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "", color: "green" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "span",
                                                  _vm._g(
                                                    _vm._b(
                                                      {},
                                                      "span",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        profile.profile_progress
                                                          ? _vm.formatNumber(
                                                              profile
                                                                .profile_progress
                                                                .points_month ||
                                                                0
                                                            )
                                                          : 0
                                                      ) +
                                                        "  /  " +
                                                        _vm._s(
                                                          profile.user_profile_sum_goal_month ||
                                                            1000
                                                        )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [_vm._v("Meta Mes")]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-progress-linear", {
                                    staticClass: "mb-2 mt-1",
                                    attrs: {
                                      value: profile.profile_progress
                                        ? (profile.profile_progress.points_day *
                                            100) /
                                            profile.user_profile_sum_goal_day ||
                                          100
                                        : 0,
                                      color: "blue",
                                      height: "8",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                [
                                  _c("v-progress-linear", {
                                    staticClass: "mb-2 mt-1",
                                    attrs: {
                                      value: profile.profile_progress
                                        ? (profile.profile_progress
                                            .points_month *
                                            100) /
                                            profile.user_profile_sum_goal_month ||
                                          1000
                                        : 0,
                                      color: "green",
                                      height: "8",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-subtitle",
                        { staticClass: "pt-2" },
                        [
                          _c(
                            "v-row",
                            [
                              profile.user &&
                              profile.password &&
                              profile.agency.status_id == 1
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "profile",
                                              params: {
                                                profile_id: profile.id,
                                              },
                                            },
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _c("list-simple-icon", {
                                            attrs: {
                                              icon: "mdi-account-arrow-left",
                                              label: "Logear Perfil",
                                              color: "blue darken-3",
                                              size: 22,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("v-col", [_vm._v(_vm._s(profile.name))]),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "", color: "orange" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-progress-linear",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "mb-2 mt-1",
                                                        attrs: {
                                                          value:
                                                            profile.profile_progress
                                                              ? profile
                                                                  .profile_progress
                                                                  .writeoff_day *
                                                                -1
                                                              : 0,
                                                          color: "orange",
                                                          height: "8",
                                                        },
                                                      },
                                                      "v-progress-linear",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  )
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          "writeoff dia " +
                                            _vm._s(
                                              profile.profile_progress
                                                ? profile.profile_progress
                                                    .writeoff_day
                                                : 0
                                            )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "", color: "red" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-progress-linear",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "mb-2 mt-1",
                                                        attrs: {
                                                          value:
                                                            profile.profile_progress
                                                              ? profile
                                                                  .profile_progress
                                                                  .writeoff_month *
                                                                -1
                                                              : 0,
                                                          color: "red",
                                                          height: "8",
                                                        },
                                                      },
                                                      "v-progress-linear",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  )
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          "writeoff mes " +
                                            _vm._s(
                                              profile.profile_progress
                                                ? profile.profile_progress
                                                    .writeoff_month
                                                : 0
                                            )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-icon",
                    { staticClass: "mt-3" },
                    [
                      _c("item-menu", {
                        attrs: {
                          menus: _vm.itemsMenu,
                          iconColor: "white",
                          btnColor: "transparent",
                          item: profile,
                        },
                        on: {
                          onItemMenu: function ($event) {
                            return _vm.onItemMenu($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", width: "600" },
          model: {
            value: _vm.editProfileDialog,
            callback: function ($$v) {
              _vm.editProfileDialog = $$v
            },
            expression: "editProfileDialog",
          },
        },
        [
          _vm.editProfileDialog
            ? _c("EditProfileCommission", {
                attrs: { action: "upd", item: _vm.perfil },
                on: {
                  closeModal: function ($event) {
                    return _vm.closeDialog($event)
                  },
                  onUpdateData: function ($event) {
                    return _vm.list()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }