var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-switch", {
    attrs: {
      inset: "",
      "hide-details": "",
      readonly: _vm.readOnly,
      loading: _vm.loading,
      "true-value": _vm.trueValue,
      "false-value": _vm.falseValue,
      color: _vm.color,
      itemKey: _vm.itemKey,
      "input-value": _vm.item[_vm.itemKey],
    },
    on: {
      change: function ($event) {
        return _vm.changeValue($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }